import React from "react";
import * as Icon from "react-feather";
const navigationConfig = [
  {
    id: "projects",
    title: "Dự án",
    type: "item",
    icon: <Icon.Airplay size={20} />,
    navLink: "/projects",
  },
  {
    id: "projects",
    title: "Kế hoạch ván khuôn",
    type: "item",
    icon: <Icon.Layers size={20} />,
    navLink: "/plan-1",
  },
  {
    id: "projects",
    title: "Kế hoạch gia cố",
    type: "item",
    icon: <Icon.Map size={20} />,
    navLink: "/plan-2",
  },
];

export default navigationConfig;
