import { combineReducers } from "redux";
import customizer from "./customizer";
import auth from "./auth";
import navbar from "./navbar/Index";
import {persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: persistReducer(
    {
      key: "root",
      storage,
    },
    auth
  ),
  navbar: navbar,
});

export default rootReducer;
